<template>
  <v-container v-if="authUser" id="donation">
    <my-wallet />

    <div class="display-1 d-flex my-8">
      <v-avatar size="32" class="" contain>
        <v-img src="/img/coins/coins-150.png"></v-img>
      </v-avatar>
      {{ isHindi ? `कॉइन स्टोर` : `Coin Store` }}
      <v-spacer></v-spacer>
      <v-btn
        @click="
          () => {
            $router.push({ name: 'redeem-coupon' });
          }
        "
        icon
        v-tooltip="$lang(`RedeemCoupon`)"
      >
        <v-avatar
          size="40"
          class="animate__animated animate__bounce animate__delay-2s"
          contain
          tile
        >
          <v-img src="/img/flaticons/gift-voucher.png"></v-img>
        </v-avatar>
      </v-btn>
    </div>

    <v-row class="justify-center">
      <v-col cols="12" md="6" v-for="product in products" :key="product.id">
        <v-card
          class="rounded-xl"
          elevation="0"
          outlined
          @click="() => handleBtnClick(product)"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="title my-0">
                {{ `${product.vcoin}` }}
                {{ $lang("vCoins") }}
              </v-card-title>

              <v-card-subtitle v-if="product.gcoin > 0">
                <v-icon>mdi-plus</v-icon>
                {{ `${product.gcoin}` }}
                {{ $lang("gCoins") }}
              </v-card-subtitle>

              <v-card-actions>
                <v-btn
                  :disabled="loading"
                  class="green darken-2 white--text ml-2 px-6 anim-shine"
                  rounded
                >
                  {{
                    isHindi
                      ? `मात्र ₹${product.amount}/-`
                      : `₹${product.amount}/- only`
                  }}
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3" size="95">
              <v-img src="/img/coins/coin-single.png"></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- <div class="display-1 text-center my-8">
      <v-avatar size="50" class="mr-2" contain tile>
        <v-img src="/img/flaticons/gift-voucher.png"></v-img>
      </v-avatar>
      <span>{{ $lang(`RedeemCoupon`) }}</span>
    </div> -->
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import { handleClick } from "../modules/payment.products";
import MyWallet from "../modules/MyWallet.vue";
export default {
  name: "products",
  components: {
    MyWallet,
  },

  data() {
    return {
      preface: null,
      productSelected: null,
      loading: false,
      products: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    isHindi() {
      return this.$store.getters.getCurrLocale == "hi";
    },
  },
  watch: {},
  methods: {
    fetchProducts() {
      bus.$emit("showWait");
      return axios
        .get("products/public?orderby=order_count|desc")
        .then((res) => {
          this.products = res.data.data;
        })
        .finally(() => {
          bus.$emit("hideWait");
        });
    },
    handleBtnClick(product) {
      return this.$router.push({ name: "product", params: { id: product.id } });
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>

<style>
.dontation .v-text-field input {
  font-size: 2rem;
}

.dontation .large-text span {
  font-size: 1.2rem;
}

.dontation .v-text-field input::-webkit-outer-spin-button,
.dontation .v-text-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
